<template>
  <v-container fill-height fluid>
    <v-layout v-if="ended">
      <v-flex>
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="10">
            <v-alert type="warning"
              >Diese Wartung wurde bereits abgeschlossen und kann nicht mehr
              verändert werden.</v-alert
            >
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-btn color="primary" top right @click="$router.go(-1)"
              >Zurück</v-btn
            >
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
    <v-layout wrap v-else>
      <v-flex>
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-btn
              color="primary"
              class="float-right"
              top
              right
              @click="$router.go(-1)"
              >Zurück</v-btn
            >
          </v-col>
          <v-col cols="12" sm="12" md="10" lg="6">
            <div class="card">
              <div class="card-header card-header-primary">
                <div class="card-title">Anlageninfomation</div>
              </div>
              <div class="card-body">
                <v-simple-table>
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <td>
                          <b>Anlagenbezeichnung</b>
                        </td>
                        <td>{{ anlage.name }}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>Gebäude</b>
                        </td>
                        <td>{{ anlage.gebaeude }}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>Standort</b>
                        </td>
                        <td>{{ anlage.standort }}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>Typ</b>
                        </td>
                        <td>{{ anlage.typ }}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>Seriennummer</b>
                        </td>
                        <td>{{ anlage.seriennummer }}</td>
                      </tr>
                      <tr>
                        <td>
                          <b>Baujahr</b>
                        </td>
                        <td>{{ anlage.baujahr }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="8">
            <v-btn
              v-if="mode !== 'history'"
              title="Anlage bearbeiten"
              @click="gotoSettings()"
              :disabled="!online"
              color="success"
              class="text-right"
              >Anlage Informationen bearbeiten</v-btn
            >
          </v-col>
        </v-row>
        <v-row v-if="status > 0 && status < 3">
          <v-col cols="12" sm="12" md="12" lg="10">
            <v-card outlined>
              <v-card-title>Bauteile</v-card-title>
              <v-card-text>
                <v-data-table
                  :headers="headers"
                  class="row-pointer"
                  @click:row="handleClick"
                  :items="anlagen"
                  :items-per-page="-1"
                  hide-default-footer
                >
                  <template v-slot:item.name="{ item }">
                    <b>{{ item.name }} / {{ item.typ }}</b>
                    ({{ item.number }} {{ item.title }})
                  </template>
                  <template v-slot:item.status="{ item }">
                    <div class="text-center">
                      <i
                        style="color: green"
                        v-if="item.status === 1"
                        class="material-icons"
                        >check_circle</i
                      >
                      <i v-else style="color: red" class="material-icons"
                        >remove_circle</i
                      >
                    </div>
                  </template>
                  <template v-slot:item.action="{ item }">
                    <v-tooltip left>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          @click="doWartung(item, true)"
                          color="primary"
                          small
                          fab
                        >
                          <i class="material-icons">playlist_add_check</i>
                        </v-btn>
                      </template>
                      <span>Checkliste Abarbeiten</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
                <v-alert v-if="hasOffline" type="warning"
                  >Für diese Wartung befinden sich Daten im
                  Offline-Speicher.</v-alert
                >
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" xs="12" sm="10" md="4" lg="3">
            <div class="card-title">Durchführungsdatum</div>
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  background-color="white"
                  v-model="formatteddate"
                  prepend-icon="event"
                  readonly
                  outlined
                  v-on="on"
                  required
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                no-title
                first-day-of-week="1"
                scrollable
                @input="menu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            xs="12"
            sm="10"
            md="7"
            lg="5"
            v-if="mode === 'execute'"
          >
            <div class="card-title">Unterschrift Service-Techniker</div>
            <vueSignature
              h="240px"
              w="450px"
              ref="signature"
              :sigOption="option"
            ></vueSignature>
            <v-btn color="error" text @click="clear()">Löschen</v-btn>
          </v-col>
          <v-col v-else cols="12" xs="12" sm="10" md="7" lg="5">
            <div class="card-title">
              Unterschrift Service-Techniker (
              <small>{{ wartung.username }}</small
              >)
            </div>
            <v-img :src="wartung.signature" contain></v-img>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="10">
            <v-btn
              v-if="!offline || offline !== 'o'"
              :disabled="!online"
              @click="saveAnlagenWartung()"
              color="success"
              >Wartung speichern</v-btn
            >
            <!--   <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  @click="saveWartungOffline()"
                  class="float-right"
                  color="orange"
                  normal
                  fab
                  dark
                >
                  <v-icon>mdi-content-save</v-icon>
                </v-btn>
              </template>
              <span>Wartung im Offline-Speicher speichern</span>
            </v-tooltip> -->
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="12">
            <v-btn
              color="primary"
              class="float-right"
              top
              right
              @click="$router.go(-1)"
              >Zurück</v-btn
            >
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="10">
            <div class="card-title">Warungshistorie</div>

            <div v-if="history.length > 0">
              <v-expansion-panels multiple focusable>
                <v-expansion-panel v-for="(history, i) in history" :key="i">
                  <v-expansion-panel-header
                    >Durchgeführt am: {{ $formatDate(history.created) }},
                    {{ history.username }}</v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    <v-card outlined class="my-4">
                      <v-card-text>
                        <v-data-table
                          :headers="headers"
                          :items-per-page="-1"
                          class="row-pointer"
                          @click:row="handleClick2"
                          :items="JSON.parse(history.items)"
                          hide-default-footer
                        >
                          <template v-slot:item.name="{ item }">
                            <b>{{ item.name }} / {{ item.typ }}</b>
                            ({{ item.number }} {{ item.title }})
                          </template>
                          <template v-slot:item.status="{ item }">
                            <div class="text-center">
                              <i
                                style="color: green"
                                v-if="item.status === 1"
                                class="material-icons"
                                >check_circle</i
                              >
                              <i
                                v-else
                                style="color: red"
                                class="material-icons"
                                >remove_circle</i
                              >
                            </div>
                          </template>
                          <template v-slot:item.action="{ item }">
                            <v-btn
                              @click="doWartung(item, false)"
                              color="primary"
                              small
                              fab
                            >
                              <i class="material-icons">playlist_add_check</i>
                            </v-btn>
                          </template>
                        </v-data-table>
                      </v-card-text>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
            <div v-else>
              Für diese Anlage wurde bisher keine Wartung durchgeführt.
            </div>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
    <v-dialog v-model="confirm" persistent max-width="590">
      <v-card>
        <v-card-title class="headline">Speichern?</v-card-title>
        <v-card-text>
          <div v-if="requiredTasks.length">
            Folgende erfoderlichen Aufgaben wurden noch nicht erledigt.
            <ul style="color: red">
              <li v-for="task in requiredTasks" :key="task.id">{{ task }}</li>
            </ul>
          </div>
          <div v-if="requiredMeasurement.length">
            Folgende erfoderlichen Messwerte wurden noch nicht erfasst.
            <ul style="color: red">
              <li v-for="task in requiredMeasurement" :key="task.id">
                {{ task }}
              </li>
            </ul>
          </div>
          <p>
            Sind Sie sicher, dass Sie das Ergebnis trotzdem speichern wollen?
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="saveAfterConfirm()"
            >Speichern</v-btn
          >
          <v-btn color="error" text @click="confirm = false">Abbrechen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog" persistent max-width="1000px" scrollable>
      <v-card>
        <v-card-title>
          <span class="headline">Bauteil Prüfung</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form">
              <v-row>
                <b>{{ newItem.name }} / {{ newItem.typ }}</b>
                ({{ newItem.number }} {{ newItem.title }})
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <v-data-table
                    :headers="headersbauteile"
                    :items="newItem.items"
                    :items-per-page="-1"
                    hide-default-footer
                    class="elevation-1"
                  >
                    <template v-slot:item.number="{ item }">
                      <span :class="{ bold: item.required }">{{
                        item.number
                      }}</span>
                    </template>
                    <template v-slot:item.name="{ item }">
                      <span :class="{ bold: item.required }">{{
                        item.name
                      }}</span>
                      <div v-if="item.measurement && item.measurement !== '-'">
                        <small v-if="item.measurement === '1'"
                          >Messung erforderlich (Soll/Ist-Wert)</small
                        >
                        <small v-else>Messung erforderlich (Ist-Wert)</small>
                      </div>
                    </template>
                    <template v-slot:item.required="{ item }">
                      <div v-if="item.required">
                        <span class="bold">X</span>
                      </div>
                      <div v-else>-</div>
                    </template>
                    <template v-slot:item.measurements="{ item }">
                      <div v-if="item.measurement && item.measurement !== '-'">
                        <div v-if="showfunctions">
                          <v-text-field
                            class="hide-details"
                            v-if="item.measurement === '2'"
                            v-model="item.measuredvalue"
                            label="Ist-Wert"
                          ></v-text-field>
                          <div v-else>
                            <v-text-field
                              v-model="item.sollvalue"
                              class="hide-details"
                              label="Soll-Wert"
                            ></v-text-field>
                            <v-text-field
                              v-model="item.istvalue"
                              class="hide-details"
                              label="Ist-Wert"
                            ></v-text-field>
                          </div>
                        </div>
                        <div v-else>
                          <div v-if="item.measurement === '2'">
                            {{ item.measuredvalue }}
                          </div>
                          <div v-else>
                            <span>
                              <b>Soll-Wert:</b>
                            </span>
                            <span>{{ item.sollvalue }}</span>
                            <br />
                            <span>
                              <b>Ist-Wert:</b>
                            </span>
                            <span>{{ item.istvalue }}</span>
                            <br />
                          </div>
                        </div>
                      </div>
                      <div v-else>-</div>
                    </template>
                    <template v-slot:item.done="{ item }">
                      <v-checkbox
                        v-if="showfunctions"
                        v-model="item.done"
                      ></v-checkbox>
                      <div v-else>
                        <i
                          style="color: green"
                          v-if="item.done === true"
                          class="material-icons"
                          >check_circle</i
                        >
                        <i style="color: red" v-else class="material-icons"
                          >remove_circle</i
                        >
                      </div>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <h4 class="mb-4 mt-4">Bemerkung</h4>
                  <v-row v-if="showfunctions">
                    <v-col cols="12" sm="9" md="9" lg="9">
                      <v-file-input
                        v-model="images"
                        color="primary accent-4"
                        counter
                        label="Bilder"
                        multiple
                        placeholder="Hier klicken um Bilder hochzuladen"
                        prepend-icon="mdi-paperclip"
                        outlined
                        :show-size="1000"
                      >
                        <template v-slot:selection="{ index, text }">
                          <v-chip
                            v-if="index < 2"
                            color="primary accent-4"
                            dark
                            label
                            small
                            >{{ text }}</v-chip
                          >

                          <span
                            v-else-if="index === 2"
                            class="overline grey--text text--darken-3 mx-2"
                            >+{{ files.length - 2 }} File(s)</span
                          >
                        </template>
                      </v-file-input>
                    </v-col>
                    <v-col cols="12" sm="3" md="3" lg="3">
                      <v-btn
                        @click="uploadImages()"
                        color="primary"
                        :disabled="images.length === 0 || !online"
                        >Bilder Hochladen</v-btn
                      >
                    </v-col>
                  </v-row>

                  <div v-if="newItem.images && newItem.images.length > 0">
                    <v-col
                      cols="12"
                      sm="6"
                      md="3"
                      lg="3"
                      v-for="(image, index) in newItem.images"
                      :key="image"
                    >
                      <v-img :src="getImage(image)" contain></v-img>
                      <v-btn
                        v-if="showfunctions"
                        @click="deleteImage(index)"
                        color="error"
                        >Bild löschen</v-btn
                      >
                    </v-col>
                  </div>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6">
                  <v-textarea
                    v-if="showfunctions"
                    outlined
                    v-model="newItem.comment"
                    label="Bemerkung Kunde"
                  ></v-textarea>
                  <div v-else>
                    <div>
                      <b>Bemerkung Kunde:</b>
                    </div>
                    <div>{{ newItem.comment }}</div>
                  </div>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6">
                  <v-textarea
                    v-if="showfunctions"
                    outlined
                    v-model="newItem.commentintern"
                    label="Bemerkung intern"
                  ></v-textarea>

                  <div v-else>
                    <div>
                      <b>Bemerkung itern:</b>
                    </div>
                    <div>{{ newItem.commentintern }}</div>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions v-if="showfunctions">
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="saveWartung()">Speichern</v-btn>
          <v-btn color="error" text @click="dialog = false">Abbrechen</v-btn>
        </v-card-actions>
        <v-card-actions v-else>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="dialog = false">Schließen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import vueSignature from "vue-signature";

export default {
  components: {
    vueSignature,
  },
  data() {
    return {
      dialog: false,
      confirm: false,
      menu: false,
      date: "",
      wartung: {},
      anlage: {},
      status: 0,
      ended: false,
      hasOffline: false,
      anlagen: [],
      images: [],
      requiredMeasurement: [],
      requiredTasks: [],
      history: [],
      option: {
        penColor: "rgb(0, 0, 0)",
        backgroundColor: "rgb(255,255,255)",
      },
      showfunctions: true,
      newItem: {
        id: "",
        name: "",
        typ: "",
        title: "",
        number: "",
        hasMangel: false,
        commentintern: "",
        comment: "",
        images: "",
        items: [],
      },
      headersbauteile: [
        {
          text: "Nummer",
          align: "left",
          sortable: false,
          value: "number",
        },
        {
          text: "Aufgabe",
          align: "left",
          sortable: false,
          value: "name",
        },
        {
          text: "Erforderlich",
          align: "center",
          value: "required",
          sortable: false,
        },
        {
          text: "Messungen",
          value: "measurements",
          sortable: false,
        },
        {
          text: "Sonstiges",
          value: "other",
          sortable: false,
        },
        {
          text: "Erledigt",
          value: "done",
          sortable: false,
        },
      ],
      newAnlage: {
        name: "",
        gebaeude: "",
      },
      headers: [
        {
          text: "Bezeichnung/Typ",
          align: "left",
          sortable: false,
          value: "name",
        },
        {
          text: "Kommentar Kunden",
          align: "left",
          sortable: false,
          value: "comment",
        },
        {
          text: "Kommentar Intern",
          align: "left",
          sortable: false,
          value: "commentintern",
        },
        {
          text: "Status",
          align: "center",
          sortable: false,
          value: "status",
        },
        {
          text: "Aktion",
          sortable: false,
          value: "action",
        },
      ],
    };
  },
  computed: {
    formatteddate() {
      return this.$formatDate(this.date);
    },
    wid() {
      return this.$route.params.wid;
    },
    blockid() {
      return this.$route.params.blockid;
    },
    id() {
      return this.$route.params.id;
    },
    mode() {
      return this.$route.params.mode;
    },
    online() {
      return this.$store.getters.online;
    },
    offline() {
      return this.$route.params.offline;
    },
    role() {
      return this.$store.getters.user.role;
    },
  },
  mounted: function () {
    const data = this.$store.getters.offlinedata;
    if (this.offline && this.offline === "o") {
      const id = parseInt(this.id);
      if (data && data.offlineanlagen && data.offlineanlagen.length) {
        const anlage = data.offlineanlagen.find((a) => {
          return a.id === id;
        });
        if (anlage) {
          this.anlage = anlage;
        }
      }
      if (data && data.offlinewartungen) {
        const wartung = this.getOfflineWartung(true);
        if (wartung) {
          this.status = 1; //wartung.status;
          this.anlagen = JSON.parse(wartung["listitems"]);
          /*  setTimeout(() => {
            if (this.$refs.signature) {
              this.$refs.signature.fromDataURL(wartung.signature);
            }
          }, 200); */
        }
      }
      return;
    }
    if (this.online) {
      this.getAnlage();
      this.getHistoricWartungen();
    } else {
      const wid = this.wid;
      const id = this.id;
      if (data && data.anlagen && data.anlagen.length) {
        const anlage = data.anlagen.find((a) => {
          return a.id === id;
        });
        if (anlage) {
          this.anlage = anlage;
        }
      }
      let wartung = this.getOfflineWartung();
      if (!wartung) {
        if (data && data.anlagenwartungen && data.anlagenwartungen.length) {
          wartung = data.anlagenwartungen.find((off) => {
            return off.wid === wid && off.anlageid === id;
          });
        }
      }
      if (wartung) {
        this.status = 1; //wartung.status;
        this.anlagen = JSON.parse(wartung["listitems"]);
        setTimeout(() => {
          if (this.$refs.signature) {
            this.$refs.signature.fromDataURL(wartung.signature);
          }
        }, 200);
      } else {
        if (this.anlage) {
          this.status = 1;
          this.anlagen = JSON.parse(this.anlage["items"]);
        }
      }
    }
  },
  methods: {
    clear() {
      if (this.$refs.signature) {
        this.$refs.signature.clear();
      }
    },
    handleClick(item) {
      this.doWartung(item, true);
    },
    handleClick2(item) {
      this.doWartung(item, false);
    },
    getAnlage() {
      let formData = new FormData();
      formData.append("id", this.id);
      let self = this;
      this.$http({
        method: "post",
        url: "getAnlage.php",
        data: formData,
      })
        .then((response) => {
          if (response.data && response.data.success && response.data.data) {
            self.anlage = response.data.data;
            this.getCurrentWartung();
          }
        })
        .catch(function (error) {
          error;
        });
    },
    getOfflineWartung(filterOnId) {
      const data = this.$store.getters.offlinedata;
      if (data && data.offlinewartungen && data.offlinewartungen.length) {
        const wid = this.wid;
        const id = this.id;
        let wartung;
        if (filterOnId) {
          wartung = data.offlinewartungen.find((off) => {
            return off.wid === wid && off.id === id;
          });
        } else {
          wartung = data.offlinewartungen.find((off) => {
            return off.wid === wid && off.anlageid === id;
          });
        }
        return wartung;
      }
    },
    getHistoricWartungen() {
      let formData = new FormData();
      formData.append("id", this.id);
      this.$http({
        method: "post",
        url: "getHistoricWartungen.php",
        data: formData,
      })
        .then((response) => {
          if (response.data && response.data.success && response.data.data) {
            this.history = response.data.data;
          }
        })
        .catch(function (error) {
          error;
        });
    },
    deleteImage(index) {
      if (this.newItem && this.newItem.images) {
        this.newItem.images.splice(index, 1);
      }
    },
    getImage(img) {
      return this.$http.defaults.serverURL + "/uploads/" + img;
    },
    getCurrentWartung() {
      let formData = new FormData();
      formData.append("wid", this.wid);
      formData.append("id", this.id);
      let self = this;
      this.$http({
        method: "post",
        url: "getWartung.php",
        data: formData,
      })
        .then((response) => {
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (response.data && response.data.success) {
            if (response.data.data) {
              const wartung = response.data.data;
              self.wartung = wartung;
              let status = wartung["status"];
              if (wartung["executiondate"]) {
                this.date = wartung["executiondate"];
              } else {
                this.date = new Date().toISOString().split(".").shift() + "Z";
              }
              if (wartung.signature) {
                setTimeout(() => {
                  if (this.$refs.signature) {
                    this.$refs.signature.fromDataURL(wartung.signature);
                  }
                }, 200);
              }
              if (
                status == 2 &&
                self.role === "worker" &&
                this.mode !== "history"
              ) {
                this.ended = true;
                return;
              }
              self.status = 1;
              let items = JSON.parse(wartung["items"]);
              if (items && items.length) {
                const anlangenitems = JSON.parse(this.anlage["items"]);
                let difference = anlangenitems.filter((x) => {
                  return !items.find((i) => {
                    return i.id === x.id;
                  });
                });
                if (difference && difference.length) {
                  items = items.concat(difference);
                }
                self.anlagen = items;
              } else {
                self.anlagen = JSON.parse(this.anlage["items"]);
              }
            } else {
              //es gibt noch keine Wartung, status setzen damit durchführen angezeigt wird
              self.status = 1;
              self.anlagen = JSON.parse(this.anlage["items"]);
              this.date = new Date().toISOString().split(".").shift() + "Z";
            }
            const wartung = this.getOfflineWartung();
            if (wartung) {
              this.hasOffline = true;
            }
          }
        })
        .catch(function (error) {
          error;
        });
    },
    doWartung(item, showfunctions) {
      this.showfunctions = showfunctions;
      if (this.mode === "history") {
        this.showfunctions = false;
      }
      this.newItem = item;
      this.dialog = true;
    },
    uploadImages() {
      const self = this;
      let formData = new FormData();
      let images = this.images;
      for (let i = 0; i < images.length; i++) {
        let file = images[i];
        formData.append("images[" + i + "]", file);
      }
      this.$http({
        method: "post",
        url: "uploadImage.php",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (
            response.data &&
            response.data.success &&
            response.data.filenames
          ) {
            this.images = [];
            if (!this.newItem.images) {
              this.newItem.images = [];
            }
            this.newItem.images = this.newItem.images.concat(
              response.data.filenames
            );
            const msg = "Bilder wurden erfolgreich hochgeladen.";
            const color = "success";
            this.$store.dispatch("snackbar", { msg, color });
          } else {
            const msg =
              "Bilder konnten nicht hochgeladen werden. Bitte versuchen Sie es erneut.";
            const color = "error";
            this.$store.dispatch("snackbar", { msg, color });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    saveWartung() {
      const items = this.newItem.items;
      this.requiredMeasurement = [];
      this.requiredTasks = [];
      let showConfirm = false;
      items.forEach((item) => {
        if (item.required && !item.done) {
          showConfirm = true;
          this.requiredTasks.push(item.number + " " + item.name);
        }
        if (item.required && item.measurement && item.measurement !== "-") {
          if (
            (item.measurement === "2" && !item.measuredvalue) ||
            (item.measurement === "1" && (!item.sollvalue || !item.istvalue))
          ) {
            showConfirm = true;
            this.requiredMeasurement.push(item.number + " " + item.name);
          }
        }
      });
      if (showConfirm) {
        this.confirm = true;
      } else {
        this.updateData(1);
      }
    },
    updateData(status) {
      var item = this.newItem;
      let index = -1;
      let anlagen = JSON.parse(JSON.stringify(this.anlagen));
      anlagen.forEach((a, i) => {
        if (a.id === item.id) {
          index = i;
        }
      });
      if (index > -1) {
        item.status = status;
        anlagen[index] = item;
        this.dialog = false;
        this.anlagen = anlagen;
      }
    },
    gotoSettings() {
      this.$router.push("/anlage/edit/" + this.id);
    },
    saveAfterConfirm() {
      this.updateData(0);
      this.confirm = false;
    },
    saveWartungOffline() {
      let status = 1;
      self = this;
      this.anlagen.forEach((a) => {
        if (!a.status || a.status === 0) {
          status = 0;
        }
      });
      const userid = this.$store.getters.user.id;

      if (this.offline && this.offline === "o") {
        const data = this.$store.getters.offlinedata;
        if (data && data.offlinewartungen) {
          const id = parseInt(this.id);
          data.offlinewartungen.forEach((w) => {
            if (w.id === id) {
              w.listitems = JSON.stringify(this.anlagen);
              w.status = status;
              w.updated_by = userid;
              let sig = "";
              if (this.$refs.signature && !this.$refs.signature.isEmpty()) {
                sig = this.$refs.signature.save();
              }
              w.signature = sig;
            }
          });
          this.$store.dispatch("setWartungenOffline", data.offlinewartungen);
        }
      } else {
        let newWartung = {};
        newWartung.anlageid = this.id;
        newWartung.mode = this.mode;
        newWartung.id = new Date().getTime();
        newWartung.wid = this.wid;
        newWartung.listitems = JSON.stringify(this.anlagen);
        newWartung.status = status;
        newWartung.updated_by = userid;
        newWartung.name = this.anlage.name;
        newWartung.gebaeude = this.anlage.gebaeude;
        newWartung.standort = this.anlage.standort;
        newWartung.typ = this.anlage.typ;
        newWartung.seriennummer = this.anlage.seriennummer;
        newWartung.baujahr = this.anlage.baujahr;
        newWartung.blockid = this.blockid;
        newWartung.executiondate = Math.round(
          new Date(this.date).getTime() / 1000
        );
        let sig = "";
        if (this.$refs.signature && !this.$refs.signature.isEmpty()) {
          sig = this.$refs.signature.save();
        }
        newWartung.signature = sig;
        newWartung.employeeId = userid;
        this.$store.dispatch("addWartungOffline", newWartung);
      }

      const msg =
        "Die Wartung wurde erfolgreich in den Offline-Speicher gespeichert.";
      const color = "success";
      this.$store.dispatch("snackbar", { msg, color });
    },
    saveAnlagenWartung() {
      let status = 1;
      self = this;
      this.anlagen.forEach((a) => {
        if (!a.status || a.status === 0) {
          status = 0;
        }
      });
      let formData = new FormData();
      formData.append("anlageid", this.id);
      const userid = this.$store.getters.user.id;
      let employeeId = "";
      let updated_by = "";
      if (this.mode === "view") {
        employeeId = this.wartung.employeeid || userid;
      } else {
        employeeId = userid;
      }
      formData.append("employeeId", employeeId);
      formData.append("wid", this.wid);
      formData.append("listitems", JSON.stringify(this.anlagen));
      formData.append("status", status);
      formData.append("updated_by", userid);
      formData.append(
        "executiondate",
        Math.round(new Date(this.date).getTime() / 1000)
      );
      let sig = "";
      if (this.$refs.signature && !this.$refs.signature.isEmpty()) {
        sig = this.$refs.signature.save();
      }
      formData.append("signature", sig);
      this.$http({
        method: "post",
        url: "addWartung.php",
        data: formData,
      })
        .then((response) => {
          if (
            response.data &&
            response.data.success === false &&
            response.data.missingToken
          ) {
            let axios = this.$http;
            this.$store
              .dispatch("logout", { axios })
              .then((resp) => {
                this.$router.push("/login");
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (response.data && response.data.success) {
            if (this.mode === "view") {
              const msg = "Änderungen wurden erfolgreich gespeichert.";
              const color = "success";
              this.$store.dispatch("snackbar", { msg, color });
            } else {
              const msg = "Die Wartung wurde erfolgreich gespeichert";
              const color = "success";
              this.$store.dispatch("snackbar", { msg, color });
              this.$router.go(-1);
            }
          }
        })
        .catch((error) => {
          const msg =
            "Die Wartung konnte nicht gespeichert werden. Bitte versuchen Sie es erneut.";
          const color = "error";
          this.$store.dispatch("snackbar", { msg, color });
        });
    },
  },
};
</script>
